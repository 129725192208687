<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-12">
        <div class="card">
          <Panel header="Simulador de Empréstimo Consignado" class="mt-3">
            <form>
              <div v-show="loading" class="loading-state">
                <i class="pi pi-spin pi-spinner"></i>
              </div>
              <div v-show="!loading" class="p-fluid formgrid grid">
                <div class="field col-6 md:col-6">
                  <label for="instituicaoFinanceira"
                    >Instituição Financeira</label
                  >
                  <Dropdown
                    id="instituicaoFinanceira"
                    v-model="v$.instituicaoFinanceira.$model"
                    :options="taxas"
                    optionLabel="InstituicaoFinanceira"
                    :class="{
                      'p-invalid':
                        submitted && v$.instituicaoFinanceira.$invalid,
                    }"
                    :filter="true"
                    @change="recalcularValoresDoEmprestimo" />
                  <div
                    v-if="submitted && v$.instituicaoFinanceira.$invalid"
                    class="p-error">
                    O campo instituição financeira é obrigatório.
                  </div>
                </div>
                <div class="field col-6 md:col-6">
                  <label for="valor">Valor do Empréstimo</label>
                  <InputNumber
                    id="valor"
                    v-model="v$.valor.$model"
                    mode="currency"
                    currency="BRL"
                    :locale="ptBR.locale"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    :class="{
                      'p-invalid': submitted && v$.valor.$invalid,
                    }"
                    @input="validarValor" />
                  <div v-if="submitted && v$.valor.$invalid" class="p-error">
                    O campo valor do empréstimo é obrigatório.
                  </div>
                </div>
                <div class="field col-6 md:col-6">
                  <label for="parcelas">Número de Parcelas</label>
                  <InputNumber
                    id="parcelas"
                    v-model="v$.parcelas.$model"
                    :class="{
                      'p-invalid': submitted && v$.parcelas.$invalid,
                    }"
                    showButtons
                    :min="1"
                    :max="120"
                    @input="validarParcelas" />
                  <div v-if="submitted && v$.parcelas.$invalid" class="p-error">
                    O campo número de parcelas é obrigatório.
                  </div>
                </div>
                <div class="field col-6 md:col-6">
                  <label for="taxa">Taxa de Juros¹</label>
                  <InputNumber
                    id="taxa"
                    v-model="taxaJurosAoMes"
                    suffix=" % a.m."
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    @input="validarTaxa" />
                </div>
              </div>
              <div class="field col-12 md:col-12">
                <Button
                  v-show="!loading"
                  label="Calcular"
                  @click="calcularValoresDoEmprestimo" />
              </div>
              <Card v-show="calculado" class="p-mt-3 bg-base-100">
                <template #title>
                  <p class="card-title">
                    {{ instituicaoFinanceira.InstituicaoFinanceira }}
                  </p>
                </template>
                <template #content>
                  <div class="stat place-items-center">
                    <p class="stat-title">Valor da parcela</p>
                    <p class="stat-value">
                      {{
                        valorParcela
                          ? formatarValor(valorParcela)
                          : 'Ainda não calculado'
                      }}
                    </p>
                  </div>
                  <p class="text-sm">
                    {{ formatarTaxa(taxaJurosAoMes) }} de juros ao mês
                  </p>
                  <p class="text-sm">
                    <b>Total do IOF:</b> {{ formatarValor(iof) }}
                  </p>
                  <p class="text-sm">
                    <b>Total de Juros:</b> {{ formatarValor(valorJuros) }}
                  </p>
                  <p class="text-sm">
                    <b>Total do Empréstimo:</b> {{ formatarValor(montante) }}
                  </p>
                </template>
              </Card>
              <div v-show="!loading">
                <p class="info-text">
                  ¹ As informações foram obtidas do relatório de taxas de juros
                  publicado regularmente pelo Banco Central. Embora não garantam
                  uma oferta com o valor exato mencionado, fornecem uma média do
                  mercado que pode auxiliar na tomada de decisão.
                </p>
              </div>
            </form>
          </Panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Decimal from 'decimal.js'
import BancoCentralService from '@/service/BancoCentralService'
import ConsignatariaService from '@/service/ConsignatariaService'

export default {
  name: 'SimuladorEmprestimoConsignado',

  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      instituicaoFinanceira: {},
      consignatariasAtiva: [],
      valor: null,
      valorParcela: null,
      valorJuros: null,
      taxaJurosAoMes: null,
      montante: null,
      parcelas: null,
      iof: null,
      taxas: [],
      calculado: false,
      submitted: false,
      loading: true,
      ptBR: {
        currency: 'BRL',
        locale: 'pt-BR',
      },
    }
  },

  validations() {
    return {
      instituicaoFinanceira: { required },
      valor: { required },
      parcelas: { required },
    }
  },

  created() {
    this.bancoCentralService = new BancoCentralService()
    this.ConsignatariaService = new ConsignatariaService(this.$http)
  },

  mounted() {
    this.carregarTaxas()
    this.carregarConsignatariasAtivas()
  },

  methods: {
    carregarTaxas() {
      this.bancoCentralService
        .getTaxasJuros()
        .then((res) => {
          const ultimoPeriodoInicio = res.value[0].InicioPeriodo
          this.taxas = res.value.filter(
            (taxa) => taxa.InicioPeriodo === ultimoPeriodoInicio,
          )
          this.taxas = this.taxas.filter((taxa) =>
            this.consignatariasAtiva.some(
              (consignataria) => consignataria.cnpj === taxa.cnpj8,
            ),
          )
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    carregarConsignatariasAtivas() {
      this.ConsignatariaService.getConsignatariasAtivas().then((res) => {
        this.consignatariasAtiva = res.map((consignataria) => {
          consignataria.cnpj = consignataria.cnpj.substring(0, 8)
          return consignataria
        })
      })
    },

    validarTaxa() {
      if (this.taxaJurosAoMes < 0) {
        this.taxaJurosAoMes = 0
      }
      this.calculado = false
    },

    validarValor() {
      if (this.valor < 0) {
        this.valor = 0
      }
      this.calculado = false
    },

    validarParcelas() {
      if (this.parcelas < 1) {
        this.parcelas = 0
      } else if (this.parcelas > 120) {
        this.parcelas = 120
      }
      this.calculado = false
    },

    formatarTaxa(valor) {
      if (valor === null) {
        return ''
      }
      valor = valor / 100
      return valor.toLocaleString('pt-BR', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },

    formatarValor(valor) {
      if (valor === null) {
        return ''
      }
      return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },

    calcularValoresDoEmprestimo() {
      if (this.validate()) {
        let valorEmprestimo = new Decimal(this.valor)
        const taxaJurosMensal = new Decimal(this.taxaJurosAoMes).div(100)
        const numeroParcelas = this.parcelas

        let valorParcela = this.calcularValorParcela(
          valorEmprestimo,
          taxaJurosMensal,
          numeroParcelas,
        )

        let saldoDevedor = valorEmprestimo

        const taxaIOFAdicional = new Decimal(0.38).div(100)
        const taxaIOFDiario = new Decimal(0.0082).div(100)

        let totalIOFDiario = new Decimal(0.0)
        for (let i = 1; i <= numeroParcelas; i++) {
          const dataAtual = this.getDataAtual()
          const dataFinal = this.getDataFinal(i)
          const juros = saldoDevedor.times(taxaJurosMensal)
          const amortizacao = valorParcela.minus(juros)
          saldoDevedor = saldoDevedor.minus(amortizacao)
          const dias = this.calcularDias(dataAtual, dataFinal)
          const iof = this.calcularIOF(dias, amortizacao, taxaIOFDiario)
          totalIOFDiario = totalIOFDiario.plus(iof)
        }

        const totalIOFAdicional = taxaIOFAdicional.times(valorEmprestimo)
        const totalIOF = totalIOFDiario.plus(totalIOFAdicional)
        this.iof = totalIOF.toNumber()
        this.valorParcela = valorParcela
        valorEmprestimo = valorEmprestimo.plus(totalIOF)

        valorParcela = this.calcularValorParcela(
          valorEmprestimo,
          taxaJurosMensal,
          numeroParcelas,
        )

        let totalJuros = new Decimal(0.0)
        saldoDevedor = valorEmprestimo
        for (let i = 1; i <= numeroParcelas; i++) {
          const juros = saldoDevedor.times(taxaJurosMensal)
          const amortizacao = valorParcela.minus(juros)
          saldoDevedor = saldoDevedor.minus(amortizacao)
          totalJuros = totalJuros.plus(juros)
        }

        this.valorJuros = totalJuros
          .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
          .toNumber()
        this.montante = valorEmprestimo
          .plus(totalJuros)
          .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
          .toNumber()
        this.valorParcela = valorParcela
        this.calculado = true
      }
    },

    calcularValorParcela(valor, taxaJuros, parcelas) {
      return valor
        .times(taxaJuros)
        .div(Decimal(1).minus(Decimal(1).plus(taxaJuros).pow(-parcelas)))
        .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
    },

    getDataAtual() {
      const dataAtual = new Date()
      dataAtual.setHours(0, 0, 0, 0)
      return dataAtual
    },

    getDataFinal(i) {
      const dataFinal = new Date()
      dataFinal.setMonth(this.getDataAtual().getMonth() + i)
      dataFinal.setHours(0, 0, 0, 0)
      return dataFinal
    },

    calcularDias(dataAtual, dataFinal) {
      const dias = Math.round(
        (dataFinal.getTime() - dataAtual.getTime()) / (1000 * 60 * 60 * 24),
      )
      return dias > 365 ? 365 : dias
    },

    calcularIOF(dias, amortizacao, taxaIOFDiario) {
      return new Decimal(dias)
        .times(amortizacao)
        .times(taxaIOFDiario)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
    },

    recalcularValoresDoEmprestimo() {
      this.taxaJurosAoMes = this.instituicaoFinanceira.TaxaJurosAoMes
      if (this.calculado) {
        this.calcularValoresDoEmprestimo()
      }
    },

    validate() {
      this.submitted = true
      this.v$.instituicaoFinanceira.$touch()
      this.v$.valor.$touch()
      this.v$.parcelas.$touch()

      if (
        this.v$.instituicaoFinanceira.$invalid ||
        this.v$.valor.$invalid ||
        this.v$.parcelas.$invalid
      ) {
        return false
      }

      return true
    },
  },
}
</script>

<style scoped>
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fa-spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card-title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 0.5em;
  text-align: center;
}

.stat-title {
  font-size: 1.2em;
  color: #666;
  text-align: center;
}

.stat-value {
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.5rem;
  color: #333;
  text-align: center;
}

.text-sm {
  font-size: 0.9em;
  color: #999;
  text-align: center;
}

.info-text {
  font-size: 0.8em;
  color: #888;
  margin: 2em 0;
  padding: 1em;
}
</style>
